<template>
  <div>
    <div class="footer">
      <div class="container">
        <div class="row" style="text-align: left">
          <div class="col-md-4">
            <div class="footer-col fourth">
              <h5>Cooperation authority</h5>
              <ul class="list-unstyled li-space-lg p-small">
                <li class="media" style="margin-left: 0">
                  <i style="color: #bbb" class="el-icon-info"></i>
                  <div class="media-body" style="color: #bbb">
                    U.S. Commodity Futures Trading Commission（CFTC）
                  </div>
                </li>
                <li class="media" style="margin-left: 0">
                  <i style="color: #bbb" class="el-icon-info"></i>
                  <div class="media-body" style="color: #bbb">
                    The Financial Industry Regulatory Authority（FINRA）
                  </div>
                </li>
                <li class="media" style="margin-left: 0">
                  <i style="color: #bbb" class="el-icon-info"></i>
                  <div class="media-body" style="color: #bbb">
                    Autorit e des March e s Financiers（AMF）
                  </div>
                </li>
                <li class="media" style="margin-left: 0">
                  <i style="color: #bbb" class="el-icon-info"></i>
                  <div class="media-body" style="color: #bbb">
                    Swiss Financial Markets Supervisory Authority（FINMA）
                  </div>
                </li>
                <li class="media" style="margin-left: 0">
                  <i style="color: #bbb" class="el-icon-info"></i>
                  <div class="media-body" style="color: #bbb">
                    Financial Conduct Authority（FCA）
                  </div>
                </li>
                <li class="media" style="margin-left: 0">
                  <i style="color: #bbb" class="el-icon-info"></i>
                  <div class="media-body" style="color: #bbb">
                    Hong Kong Monetary Authority（HKMA）
                  </div>
                </li>
                <li class="media" style="margin-left: 0">
                  <i style="color: #bbb" class="el-icon-info"></i>
                  <div class="media-body" style="color: #bbb">
                    Monetary Authority of Singapore（MAS）
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div class="col-md-4">
            <div class="footer-col second">
              <h5>Our Branch Locations</h5>
              <ul class="list-unstyled li-space-lg p-small">
                <li class="media" style="margin-left: 0">
                  <i style="color: #bbb" class="el-icon-caret-right"></i>
                  <div class="media-body" style="color: #bbb">
                    <b>Robert Tsigler headquarters: </b>
                    <br />
                    Mozilla Corp, 149 New Montgomery St, Fl 4, San Francisco, CA
                    94105-3740
                  </div>
                </li>
                <li class="media" style="margin-left: 0">
                  <i style="color: #bbb" class="el-icon-caret-right"></i>
                  <div class="media-body" style="color: #bbb">
                    <b>Office in Bern, Switzerland: </b>
                    <br />
                    Sidlerstrasse 4, 3012 Bern, Switzerland
                  </div>
                </li>
                <li class="media" style="margin-left: 0">
                  <i style="color: #bbb" class="el-icon-caret-right"></i>
                  <div class="media-body" style="color: #bbb">
                    <b>Office in Paris, France: </b>
                    <br />
                    62 Rue Saint-Antoine, 75186 Paris, France
                  </div>
                </li>
                <li class="media" style="margin-left: 0">
                  <i style="color: #bbb" class="el-icon-caret-right"></i>
                  <div class="media-body" style="color: #bbb">
                    <b>London Office, UK: </b>
                    <br />
                    41 Lothbury, London EC2R 7HG，U.K.
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div class="col-md-4">
            <div class="footer-col fourth">
              <h5>Contact Us</h5>
              <ul class="list-unstyled li-space-lg p-small">
                <h4 style="color: #bbb">Jimson Law Firm</h4>
                <li class="media" style="margin-left: 0">
                  <div class="media-body" style="color: #bbb"></div>
                </li>
                <li class="media" style="margin-left: 0">
                  <div class="media-body" style="color: #bbb">
                    Address：Mozilla Corp, 149 New Montgomery St, Fl 4, San
                    Francisco, CA 94105-3740
                  </div>
                </li>
                <li class="media" style="margin-left: 0">
                  <div class="media-body" style="color: #bbb">
                    Email：ripplegrayllpoffice@gmail.com
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="copyright">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <p class="p-small">
              Copyright © 2022
              <a href="/">Jimson Law Firm</a>
              - All rights reserved keywords：
              <a href="/">
                <strong>Jimson</strong>
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style>
.footer {
  padding-top: 4.75rem;
  padding-bottom: 2rem;
  background: linear-gradient(
      to bottom right,
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0.8)
    ),
    url("@/assets/images/header-background.jpg") center center no-repeat;
  background-size: cover;
}

.footer .footer-col {
  margin-bottom: 2.25rem;
}

.footer h5,
.footer p,
.footer a {
  color: #bbb;
}

.footer h5 {
  margin-bottom: 0.5rem;
}

.footer .list-unstyled .media-body {
  margin-left: 0.625rem;
}

h4 {
  color: #333;
  font: 600 1.25rem / 1.875rem "Montserrat", sans-serif;
}

.copyright {
  padding-top: 1.5rem;
  padding-bottom: 0.375rem;
  background-color: #000;
  text-align: center;
}

.copyright .p-small {
  color: #999;
  font-size: 0.75rem;
  line-height: 1.375rem;
}

.copyright a {
  color: #999;
}
</style>
