<template>
  <div class="nav navbar">
    <div class="nav-left">
      <el-image
        style="width: 16.875rem; height: 2.625rem; margin-top: 0.5rem"
        :src="require('@/assets/images/en-logo.jpg')"
        @click="navTo('/')"
      ></el-image>
    </div>
    <div v-if="show" class="nav-right">
      <el-menu
        style="border: none"
        mode="horizontal"
        text-color="#333"
        active-text-color="#069"
      >
        <el-menu-item index="1"
          ><span @click="navTo('/about')" class="nav-menu"
            >About Us</span
          ></el-menu-item
        >
        <el-menu-item index="2"
          ><span @click="navTo('/services')" class="nav-menu"
            >Our services</span
          ></el-menu-item
        >
        <el-submenu index="3">
          <template slot="title"
            ><span class="nav-menu">Recovery</span></template
          >
          <el-menu-item index="3-1"
            ><span @click="navTo('/options')" class="nav-item"
              >Binary Options</span
            ></el-menu-item
          >
          <el-menu-item index="3-2"
            ><span @click="navTo('/cryptocurrency')" class="nav-item"
              >Cryptocurrency</span
            ></el-menu-item
          >
          <el-menu-item index="3-3"
            ><span @click="navTo('/forex')" class="nav-item"
              >Forex</span
            ></el-menu-item
          >
          <el-menu-item index="3-4"
            ><span @click="navTo('/trade')" class="nav-item"
              >Stock Trading</span
            ></el-menu-item
          >
          <el-menu-item index="3-5"
            ><span @click="navTo('/credit')" class="nav-item"
              >Credit card phishing</span
            ></el-menu-item
          >
          <el-menu-item index="3-6"
            ><span @click="navTo('/submission')" class="nav-item"
              >My Submission</span
            ></el-menu-item
          >
        </el-submenu>
        <el-submenu index="4">
          <template slot="title"><span class="nav-menu">Case</span></template>
          <el-menu-item index="4-1"
            ><span @click="navTo('/new')" class="nav-item"
              >New cases</span
            ></el-menu-item
          >
          <el-menu-item index="4-2"
            ><span @click="navTo('/accepted')" class="nav-item"
              >Accepted cases</span
            ></el-menu-item
          >
        </el-submenu>
        <el-menu-item index="5"
          ><span @click="navTo('/team')" class="nav-menu"
            >Lawyers team</span
          ></el-menu-item
        >
        <el-menu-item index="6"
          ><span @click="navTo('/careers')" class="nav-menu"
            >Careers</span
          ></el-menu-item
        >
      </el-menu>
    </div>

    <div v-else class="nav-right">
      <i
        v-if="!drawer"
        @click="menuDrawer(true)"
        class="el-icon-menu"
        style="font-size: 2rem"
      ></i>
      <i
        v-else
        @click="menuDrawer(false)"
        class="el-icon-error"
        style="font-size: 2rem"
      ></i>
    </div>

    <el-drawer
      title=""
      :visible.sync="drawer"
      direction="ttb"
      :modal="false"
      :show-close="false"
      :wrapperClosable="false"
      :with-header="false"
      style="margin-top: 5rem;height: 50rem;"
    >
      <el-menu
        class="el-menu-vertical-demo"
        style="border: none;text-align: left;"
        text-color="#333"
        active-text-color="#069"
      >
        <el-menu-item index="1"
          ><span @click="navTo('/about')" class="nav-menu nav-item"
            >About Us</span
          ></el-menu-item
        >
        <el-menu-item index="2"
          ><span @click="navTo('/services')" class="nav-menu nav-item"
            >Our services</span
          ></el-menu-item
        >
        <el-submenu index="3">
          <template slot="title"
            ><span class="nav-menu nav-item">Recovery</span></template
          >
          <el-menu-item index="3-1"
            ><span @click="navTo('/options')" class="nav-item"
              >Binary Options</span
            ></el-menu-item
          >
          <el-menu-item index="3-2"
            ><span @click="navTo('/cryptocurrency')" class="nav-item"
              >Cryptocurrency</span
            ></el-menu-item
          >
          <el-menu-item index="3-3"
            ><span @click="navTo('/forex')" class="nav-item"
              >Forex</span
            ></el-menu-item
          >
          <el-menu-item index="3-4"
            ><span @click="navTo('/trade')" class="nav-item"
              >Stock Trading</span
            ></el-menu-item
          >
          <el-menu-item index="3-5"
            ><span @click="navTo('/credit')" class="nav-item"
              >Credit card phishing</span
            ></el-menu-item
          >
          <el-menu-item index="3-6"
            ><span @click="navTo('/submission')" class="nav-item"
              >My Submission</span
            ></el-menu-item
          >
        </el-submenu>
        <el-submenu index="4">
          <template slot="title"><span class="nav-menu nav-item">Case</span></template>
          <el-menu-item index="4-1"
            ><span @click="navTo('/new')" class="nav-item"
              >New cases</span
            ></el-menu-item
          >
          <el-menu-item index="4-2"
            ><span @click="navTo('/accepted')" class="nav-item"
              >Accepted cases</span
            ></el-menu-item
          >
        </el-submenu>
        <el-menu-item index="5"
          ><span @click="navTo('/team')" class="nav-menu nav-item"
            >Lawyers team</span
          ></el-menu-item
        >
        <el-menu-item index="6"
          ><span @click="navTo('/careers')" class="nav-menu nav-item"
            >Careers</span
          ></el-menu-item
        >
      </el-menu>
    </el-drawer>
  </div>
</template>

<script>
export default {
  name: "NavBar",
  props: ["show"],
  data() {
    return {
      screenWith: null,
      drawer: false,
    };
  },
  methods: {
    navTo(val) {
      this.$router.push(val);
      this.drawer = false
    },
    menuDrawer(val) {
      this.drawer = val;
    },
  },
};
</script>

<style>
.nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 5rem;
  background-color: #fff;
  width: 100%;
  z-index: 999;
}

.nav-left {
  float: left;
  /* margin-left: 2rem; */
}

.nav-right {
  float: right;
  /* margin-right: 2rem; */
  font: 600 0.875rem/0.875rem "Montserrat", sans-serif;
}

.nav-item {
  font: 600 0.875rem/0.875rem "Montserrat", sans-serif;
}

.nav-item:hover {
  color: #dc3545;
}

.nav-menu:hover {
  color: #069;
}

.navbar {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0.5rem 0.5rem;
}
</style>
