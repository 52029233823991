<template>
  <div id="app">
    <NavBar :show="show"></NavBar>
    <router-view />
    <Footer></Footer>
    <el-backtop target=""></el-backtop>
  </div>
</template>

<script>
import NavBar from "@/components/nav/NavBar.vue";
import Footer from "@/components/footer/Footer.vue";

export default {
  name: "app",
  data() {
    return {
      show: true,
      screenWith: null,
    };
  },
  components: {
    NavBar,
    Footer,
  },
  mounted() {
    this.screenWith = document.body.clientWidth;
    // console.log(this.screenWith);
    window.onresize = () => {
      return (() => {
        this.screenWith = document.body.clientWidth;
      })();
    };
  },
  watch: {
    screenWith: {
      handler: function (val) {
        if (val < 1200) {
          this.show = false;
          this.dist = false;
          // console.log(val);
        } else {
          this.show = true;
          // console.log(123)
          this.dist = true;
        }
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  border: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #555;
  font: 400 1rem/1.625rem "Montserrat", sans-serif;
}
</style>
