<template>
  <div class="home">
    <!-- <NavBar :show="show"></NavBar> -->

    <!-- <div class="background">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <h1 class="h1-style">Jimson Law Firm</h1>
            <p class="p-style">
              Our purposes : to provide customers with excellent cross-state and
              cross-language legal help and solutions.
            </p>
          </div>
        </div>
      </div>

      <div class="outer-container">
        <div class="slider-container">
          <div class="swiper-container">
            <el-carousel
              height="32.4rem"
              arrow="never"
              autoplay
              indicator-position="none"
            >
              <el-carousel-item v-for="item in 4" :key="item">
                <el-image
                  class="img-fluid dynamic-style"
                  fit="cover"
                  :src="require('@/assets/images/214b151a3cd01df8ccc0382390c0bddba930742413cb5-e9WGOD_fw658.webp')"
                ></el-image>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
    </div> -->
    
    <div class="StyledHeadlineHero_main__Xc4mJ" style="margin-top: 5rem;background-color: #fbf9f5">
      <div
        class="StyledHeadlineHero_bg-media__PeO3j StyledHeadlineHero_has-video__zS5P0"
        :style="{'height': dist ? '34.375rem' : ''}"
      >
        <div class="StyledHeadlineHero_video-container__AQ8yE">
          <div class="StyledHeadlineHero_video__fvFZC">
            <el-carousel
              height="32.4rem"
              arrow="never"
              autoplay
              indicator-position="none"
            >
              <el-carousel-item v-for="item in 4" :key="item">
                <el-image
                  class="dynamic-style"
                  fit="cover"
                  :src="require('@/assets/images/214b151a3cd01df8ccc0382390c0bddba930742413cb5-e9WGOD_fw658.webp')"
                ></el-image>
              </el-carousel-item>
            </el-carousel>
          </div>
          <!-- <video
            class="StyledHeadlineHero_video__fvFZC"
            autoplay
            muted
            loop
            playsinline
          >
            <source
              src="@/assets/dshgaukrruethgewrghafk.mp4"
              type="video/mp4"
            />
          </video> -->
        </div>
      </div>
    </div>

    <div class="basic-2" :style="{ 'padding-top': dist ? '6rem' : '6rem' }">
      <div class="container">
        <div class="row">
          <h2 style="padding-left: 10px">Our services</h2>
          <div :class="{ 'col-lg-6': dist, 'col-lg-4': !dist }">
            <ul class="list-unstyled li-space-lg first">
              <li class="media">
                <i class="bullet">»</i>
                <div class="media-body">
                  <h4>
                    <font color="#E62129">Investment fraud</font>
                  </h4>
                  <p>
                    If you suspect that you have been scammed by digital
                    currency, financial products, or exchange trading
                    investments, please contact us and our lawyers will analyze
                    your situation and help you recover the maximum amount of
                    money you have lost.
                  </p>
                </div>
              </li>
              <li class="media">
                <i class="bullet">»</i>
                <div class="media-body">
                  <h4>
                    <font color="#E62129">Chinese citizens suffer fraud</font>
                  </h4>
                  <p>
                    If you are a Chinese citizen and have encountered a scam in
                    the United States, or suspect that you have been scammed,
                    please contact us as soon as possible and our attorneys will
                    analyze your situation and help you minimize your financial
                    losses or recover them to the greatest extent possible.
                  </p>
                </div>
              </li>
              <li class="media">
                <i class="bullet">»</i>
                <div class="media-body">
                  <h4>
                    <font color="#E62129">Criminal</font>
                  </h4>
                  <p>
                    A professional legal team will guide you through every
                    hurdle to obtain legal status, realize your immigrant dream
                    or defend your right to live and work in the United States.
                  </p>
                </div>
              </li>
            </ul>
          </div>

          <div :class="{ 'col-lg-6': dist, 'col-lg-4': !dist }">
            <ul class="list-unstyled li-space-lg first">
              <li class="media">
                <i class="bullet">»</i>
                <div class="media-body">
                  <h4>
                    <font color="#E62129">Criminal</font>
                  </h4>
                  <p>
                    Whether you have been charged with a serious crime, or
                    merely issued a summons for a traffic violation, attorneys
                    at Demidchik Law Firm understand your predicament and
                    possess the advocacy skills to help you through and out of
                    the criminal justice system.
                  </p>
                </div>
              </li>
              <li class="media">
                <i class="bullet">»</i>
                <div class="media-body">
                  <h4>
                    <font color="#E62129">Civil and Commercial Litigation</font>
                  </h4>
                  <p>
                    Our civil litigation attorneys are familiar not only with
                    New York State's Law but also Federal Laws and Regulations.
                    Majority of our attorneys have admitted to practice in
                    multiple states, and have abundant experiences in extensive
                    civil litigations.
                  </p>
                </div>
              </li>
              <li class="media">
                <i class="bullet">»</i>
                <div class="media-body">
                  <h4>
                    <font color="#E62129"
                      >Accidental injury and work injury</font
                    >
                  </h4>
                  <p>
                    The biggest difference in damages between accidental and
                    industrial injury suits and industrial injury suits is that
                    you are not entitled to the pain and suffering in an
                    industrial injury suit and in a personal injury claim, you
                    are entitled to recover all the losses that you have
                    suffered.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="basic-4">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <h2>Jimson Law Firm</h2>
            <div class="image-container">
              <div class="video-wrapper">
                <el-image
                  class="img-fluid"
                  :src="require('@/assets/images/zh-intro.jpg')"
                ></el-image>
                <span class="video-play-button">
                  <span></span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="basic-1">
      <div class="container">
        <div class="row">
          <div :class="{ 'col-lg-6': dist, 'col-lg-4': !dist }">
            <el-image
              class="img-fluid"
              :src="require('@/assets/images/instructor.jpg')"
            ></el-image>
          </div>
          <div :class="{ 'col-lg-6': dist, 'col-lg-4': !dist }">
            <div class="text-container">
              <h2>Our Purpose</h2>
              <p>
                <i class="el-icon-caret-right"></i>&nbsp;
                <b>Broad area of practice.</b>
                We practice immigration law, criminal law, as well as all types
                of civil litigation. Take a closer look at our practice areas
              </p>
              <p>
                <i class="el-icon-caret-right"></i>&nbsp;
                <b>Experience.</b>
                Our experienced, experienced attorneys are licensed in all over
                United states and always be known for outstanding results for
                our clients.
              </p>
              <p>
                <i class="el-icon-caret-right"></i>&nbsp;
                <b>We can speak your language.</b>
                All paralegals and management team members are multilingual and
                we believe that excellent results can only be achieved by fully
                understanding your situation and the details of your case.
              </p>
              <p>
                <i class="el-icon-caret-right"></i>&nbsp; Our consulting and
                costumer service team are online 24/7 . Our team is capable
                answer any of your questions in your language. Feel free to
                contact us any time.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="cards">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <h2>Lawyers team</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="card">
              <div class="card-image">
                <img
                  src="@/assets/images/A69BCA12-5056-BF0B-630C5D8D2DED4173.jpg"
                />
              </div>
              <div class="card-body">
                <h4 class="card-title">David M.Brady</h4>
                <p>&nbsp;</p>
              </div>
            </div>
            <div class="card">
              <div class="card-image">
                <img
                  src="@/assets/images/69416721-093D-32C0-1B9349A87B80087E.jpg"
                />
              </div>
              <div class="card-body">
                <h4 class="card-title">Andrea L. Alexandre</h4>
                <p>&nbsp;</p>
              </div>
            </div>
            <div class="card">
              <div class="card-image">
                <img
                  src="@/assets/images/38C93928-C1FF-A134-A5358D351C63C0EE.jpg"
                />
              </div>
              <div class="card-body">
                <h4 class="card-title">Rajiv Radia</h4>
                <p>&nbsp;</p>
              </div>
            </div>
            <div class="card">
              <div class="card-image">
                <img
                  src="@/assets/images/439B52CC-CA47-0F4B-705EC353A62A6DDE.jpg"
                />
              </div>
              <div class="card-body">
                <h4 class="card-title">Eric H. Feiler</h4>
                <p>&nbsp;</p>
              </div>
            </div>
            <div class="card">
              <div class="card-image">
                <img
                  src="@/assets/images/A72EDBBA-5056-BF0B-63C176D1B7C569B0.jpg"
                />
              </div>
              <div class="card-body">
                <h4 class="card-title">Dale G. Mullen</h4>
                <p>&nbsp;</p>
              </div>
            </div>
            <div class="card">
              <div class="card-image">
                <img
                  src="@/assets/images/B91423B3-5056-BF0B-63BBD48C28200900.jpg"
                />
              </div>
              <div class="card-body">
                <h4 class="card-title">D. Shane Smith</h4>
                <p>&nbsp;</p>
              </div>
            </div>
            <div class="card">
              <div class="card-image">
                <img
                  src="@/assets/images/D3DDC4C8-5056-BF0B-63E279EF6E195557.jpg"
                />
              </div>
              <div class="card-body">
                <h4 class="card-title">Stephen M. Faraci, Sr.</h4>
                <p>&nbsp;</p>
              </div>
            </div>
            <div class="card">
              <div class="card-image">
                <img
                  src="@/assets/images/D00143B6-5056-BF0B-6371606656642D62.jpg"
                />
              </div>
              <div class="card-body">
                <h4 class="card-title">Vernon E. Inge, Jr.</h4>
                <p>&nbsp;</p>
              </div>
            </div>
            <div class="card">
              <div class="card-image">
                <img
                  src="@/assets/images/FB420EB9-5056-BF0B-63CB1BD6866E0B58.jpg"
                />
              </div>
              <div class="card-body">
                <h4 class="card-title">John C. Selbach</h4>
                <p>&nbsp;</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="dist" class="slider-2">
      <div class="container">
        <div class="row">
          <h3 style="padding-left: 10px">Latest News</h3>
          <div class="col-lg-12">
            <div class="slider-container">
              <div
                class="swiper-container text-slider swiper-container-horizontal"
              >
                <el-carousel
                  arrow="always"
                  height="6.25rem"
                  indicator-position="none"
                >
                  <el-carousel-item>
                    <div style="display: flex">
                      <div>
                        <div class="image-wrapper">
                          <el-image
                            class="img-fluid"
                            :src="
                              require('@/assets/images/2022042313360859859.jpg')
                            "
                          ></el-image>
                        </div>
                        <div class="text-wrapper">
                          <div class="testimonial-author">
                            Pulled over by Police
                          </div>
                          <div class="testimonial-text">
                            <p>
                              Getting pulled over by police can be a frightening
                              experience, especially for those
                            </p>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div class="image-wrapper">
                          <el-image
                            class="img-fluid"
                            :src="
                              require('@/assets/images/KINi_220415_333868.jpg')
                            "
                          ></el-image>
                        </div>
                        <div class="text-wrapper">
                          <div class="testimonial-author">Asylum Advisory</div>
                          <div class="testimonial-text">
                            <p>
                              Our office has received many phone calls regarding
                              President Trump’s impact on this
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </el-carousel-item>
                  <el-carousel-item>
                    <div style="display: flex">
                      <div>
                        <div class="image-wrapper">
                          <el-image
                            class="img-fluid"
                            :src="
                              require('@/assets/images/2022042313290763763.jpg')
                            "
                          ></el-image>
                        </div>
                        <div class="text-wrapper">
                          <div class="testimonial-author">
                            Some changes to expect in the
                          </div>
                          <div class="testimonial-text">
                            <p>
                              It is still unclear exactly how President Trump
                              will change the mortgage industry. One thi
                            </p>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div class="image-wrapper">
                          <el-image
                            class="img-fluid"
                            :src="
                              require('@/assets/images/KINi_220415_333868.jpg')
                            "
                          ></el-image>
                        </div>
                        <div class="text-wrapper">
                          <div class="testimonial-author">Asylum Advisory</div>
                          <div class="testimonial-text">
                            <p>
                              Our office has received many phone calls regarding
                              President Trump’s impact on this
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </el-carousel-item>
                </el-carousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="slider-2">
      <div class="container">
        <div class="row">
          <h3 style="padding-left: 15px">Latest News</h3>
          <div class="col-lg-12">
            <div class="slider-container">
              <div
                class="swiper-container text-slider swiper-container-horizontal"
              >
                <el-carousel arrow="always" indicator-position="none">
                  <el-carousel-item>
                    <div>
                      <div class="image-wrapper">
                        <el-image
                          class="img-fluid"
                          :src="
                            require('@/assets/images/2022042313360859859.jpg')
                          "
                        ></el-image>
                      </div>
                      <div class="text-wrapper" style="text-align: center">
                        <div class="testimonial-author">
                          Pulled over by Police
                        </div>
                        <div class="testimonial-text">
                          <p>
                            Getting pulled over by police can be a frightening
                            experience, especially for those
                          </p>
                        </div>
                      </div>
                    </div>
                  </el-carousel-item>
                  <el-carousel-item>
                    <div>
                      <div class="image-wrapper">
                        <el-image
                          class="img-fluid"
                          :src="
                            require('@/assets/images/KINi_220415_333868.jpg')
                          "
                        ></el-image>
                      </div>
                      <div class="text-wrapper" style="text-align: center">
                        <div class="testimonial-author">Asylum Advisory</div>
                        <div class="testimonial-text">
                          <p>
                            Our office has received many phone calls regarding
                            President Trump’s impact on this
                          </p>
                        </div>
                      </div>
                    </div>
                  </el-carousel-item>
                  <el-carousel-item>
                    <div>
                      <div class="image-wrapper">
                        <el-image
                          class="img-fluid"
                          :src="
                            require('@/assets/images/2022042313290763763.jpg')
                          "
                        ></el-image>
                      </div>
                      <div class="text-wrapper" style="text-align: center">
                        <div class="testimonial-author">
                          Some changes to expect in the
                        </div>
                        <div class="testimonial-text">
                          <p>
                            It is still unclear exactly how President Trump will
                            change the mortgage industry. One thi
                          </p>
                        </div>
                      </div>
                    </div>
                  </el-carousel-item>
                </el-carousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="basic-3">
      <div class="container">
        <div class="row" style="margin-left: 15px; margin-right: 15px">
          <div class="text-container" :style="{ width: dist ? '50%' : '100%' }">
            <h2 style="color: #333; margin-bottom: 1rem">Careers</h2>
            <p>
              At Jimson Law Firm, we are always interested in expanding our
              team with new, experienced talent. We offer competitive
              compensation and a close-knit, collaborative environment with a
              variety of challenging work. If you are interested in a career
              opportunity at Law Office Of Robert Tsigler, please send your
              inquiry and CV to:
            </p>
            <el-button
              @click="onDialog"
              class="career-button"
              type="primary"
              round
              >Career List</el-button
            >
          </div>
        </div>
      </div>
    </div>

    <el-dialog title="" :visible.sync="dialogVisible" width="80%">
      <div class="mfp-content lightbox-basic">
        <div class="container">
          <div class="row">
            <div class="col-lg-8">
              <div class="image-container">
                <el-image
                  class="img-fluid"
                  :src="require('@/assets/images/emplogy.jpg')"
                ></el-image>
              </div>
            </div>
            <div class="col-lg-4">
              <h3 style="border-bottom: #333 1px solid">Careers</h3>
              <h5></h5>
              <h5>Law Clerk</h5>
              <h5>Associate Attorney</h5>
              <h5>Accountant</h5>
              <h5>Attorney (Santa Clara, CA)</h5>
              <ul class="list-unstyled li-space-lg">
                <li class="media">
                  <div class="media-body">
                    If you are interested in a career opportunity at Law Office
                    Of Robert Tsigler, please send your inquiry and CV to :
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">Close</el-button>
      </span>
    </el-dialog>

    <div class="form-3">
      <div class="container">
        <div class="row">
          <div :class="{ 'col-lg-6': dist, 'col-lg-4': !dist }">
            <div class="text-container">
              <h2>Transparency is important to us</h2>
              <P
                >The TrustScore isn’t just a simple average of all reviews. It’s
                based on multiple factors like the age and number of reviews.
                Whether or not a business actively asks customers to write
                reviews also impacts the TrustScore.</P
              >
            </div>
          </div>
          <div :class="{ 'col-lg-6': dist, 'col-lg-4': !dist }">
            <el-form :model="form">
              <el-form-item>
                <el-input v-model="form.name" placeholder="Name"></el-input>
              </el-form-item>
              <el-form-item>
                <el-input v-model="form.region" placeholder="Title"></el-input>
              </el-form-item>
              <el-form-item>
                <el-select v-model="form.star" style="width: 100%">
                  <el-option label="5-star" value="5"></el-option>
                  <el-option label="4-star" value="4"></el-option>
                  <el-option label="3-star" value="3"></el-option>
                  <el-option label="2-star" value="2"></el-option>
                  <el-option label="1-star" value="1"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-input
                  type="textarea"
                  v-model="form.type"
                  placeholder="Message"
                  rows="4"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <div style="display: flex">
                  <el-input v-model="form.captcha"></el-input>
                  <div>
                    <el-image
                      :src="require('@/assets/images/captcha.png')"
                    ></el-image>
                  </div>
                </div>
              </el-form-item>
              <el-form-item>
                <el-button
                  plain
                  style="
                    width: 100%;
                    background-color: #e62129;
                    border: #333 0.125rem solid;
                    color: #fff;
                  "
                  round
                  @click="submit"
                  >SUBMIT</el-button
                >
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>

    <div class="basic-2">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <h2>Customer Reviews and Testimonials</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4">
            <ul class="list-unstyled li-space-lg first">
              <li class="media">
                <el-image
                  style="
                    width: 3.25rem;
                    height: 3.25rem;
                    margin-right: 1rem;
                    border-radius: 0.325rem;
                  "
                  :src="require('@/assets/images/04.jpg')"
                ></el-image>
                <div class="media-body">
                  <h5 style="font-size: 1rem; line-height: 1.75rem">Mike</h5>
                  <el-rate v-model="value1" disabled text-color="#ff9900">
                  </el-rate>
                  <p
                    style="
                      margin-left: -4.25rem;
                      font-size: 11px;
                      line-height: 150%;
                    "
                  >
                    <i class="el-icon-date"></i>
                    &nbsp;24 Mar ,2023
                  </p>
                  <h5
                    style="
                      font-size: 1rem;
                      line-height: 1.75rem;
                      margin-left: -4.25rem;
                    "
                  >
                    fraudulent company
                  </h5>
                  <p style="font-size: 0.875rem; margin-left: -4.25rem">
                    I really did not expect that I already have years of
                    investment experience will meet fraud cases, at one time the
                    shareholder introduced a decentraliz
                  </p>
                </div>
              </li>

              <li class="media">
                <el-image
                  style="
                    width: 3.25rem;
                    height: 3.25rem;
                    margin-right: 1rem;
                    border-radius: 0.325rem;
                  "
                  :src="require('@/assets/images/46.jpg')"
                ></el-image>
                <div class="media-body">
                  <h5 style="font-size: 1rem; line-height: 1.75rem">
                    Darren Edwin Roderick
                  </h5>
                  <el-rate v-model="value4" disabled text-color="#ff9900">
                  </el-rate>
                  <p
                    style="
                      margin-left: -4.25rem;
                      font-size: 11px;
                      line-height: 150%;
                    "
                  >
                    <i class="el-icon-date"></i>
                    &nbsp;25 Mar ,2023
                  </p>
                  <h5
                    style="
                      font-size: 1rem;
                      line-height: 1.75rem;
                      margin-left: -4.25rem;
                    "
                  >
                    Love stock fraud
                  </h5>
                  <p style="font-size: 0.875rem; margin-left: -4.25rem">
                    I encountered an online stock investment scam during one of
                    my online girlfriend''s recommended investments. At the
                    beginning they guided me to make s
                  </p>
                </div>
              </li>
            </ul>
          </div>

          <div class="col-lg-4">
            <ul class="list-unstyled li-space-lg first">
              <li class="media">
                <el-image
                  style="
                    width: 3.25rem;
                    height: 3.25rem;
                    margin-right: 1rem;
                    border-radius: 0.325rem;
                  "
                  :src="require('@/assets/images/31.jpg')"
                ></el-image>
                <div class="media-body">
                  <h5 style="font-size: 1rem; line-height: 1.75rem">Mike</h5>
                  <el-rate v-model="value2" disabled text-color="#ff9900">
                  </el-rate>
                  <p
                    style="
                      margin-left: -4.25rem;
                      font-size: 11px;
                      line-height: 150%;
                    "
                  >
                    <i class="el-icon-date"></i>
                    &nbsp;18 Mar ,2023
                  </p>
                  <h5
                    style="
                      font-size: 1rem;
                      line-height: 1.75rem;
                      margin-left: -4.25rem;
                    "
                  >
                    Stock Investment Scams
                  </h5>
                  <p style="font-size: 0.875rem; margin-left: -4.25rem">
                    I was a victim of an investment scam that started when I was
                    lured by an investment of only $2500. At first everything
                    was friendly and they helped me
                  </p>
                </div>
              </li>

              <li class="media">
                <el-image
                  style="
                    width: 3.25rem;
                    height: 3.25rem;
                    margin-right: 1rem;
                    border-radius: 0.325rem;
                  "
                  :src="require('@/assets/images/15.jpg')"
                ></el-image>
                <div class="media-body">
                  <h5 style="font-size: 1rem; line-height: 1.75rem">
                    Vincent Michael
                  </h5>
                  <el-rate v-model="value5" disabled text-color="#ff9900">
                  </el-rate>
                  <p
                    style="
                      margin-left: -4.25rem;
                      font-size: 11px;
                      line-height: 150%;
                    "
                  >
                    <i class="el-icon-date"></i>
                    &nbsp;18 Mar ,2023
                  </p>
                  <h5
                    style="
                      font-size: 1rem;
                      line-height: 1.75rem;
                      margin-left: -4.25rem;
                    "
                  >
                    Cryptocurrency Scams
                  </h5>
                  <p style="font-size: 0.875rem; margin-left: -4.25rem">
                    I was a victim of a cryptocurrency scam and didn''t find out
                    about the scam situation until later. I didn''t know what to
                    do or where to go or who to
                  </p>
                </div>
              </li>
            </ul>
          </div>

          <div class="col-lg-4">
            <ul class="list-unstyled li-space-lg first">
              <li class="media">
                <el-image
                  style="
                    width: 3.25rem;
                    height: 3.25rem;
                    margin-right: 1rem;
                    border-radius: 0.325rem;
                  "
                  :src="require('@/assets/images/20.jpg')"
                ></el-image>
                <div class="media-body">
                  <h5 style="font-size: 1rem; line-height: 1.75rem">
                    LianHua Wong
                  </h5>
                  <el-rate v-model="value3" disabled text-color="#ff9900">
                  </el-rate>
                  <p
                    style="
                      margin-left: -4.25rem;
                      font-size: 11px;
                      line-height: 150%;
                    "
                  >
                    <i class="el-icon-date"></i>
                    &nbsp;24 Mar ,2023
                  </p>
                  <h5
                    style="
                      font-size: 1rem;
                      line-height: 1.75rem;
                      margin-left: -4.25rem;
                    "
                  >
                    Option scam
                  </h5>
                  <p style="font-size: 0.875rem; margin-left: -4.25rem">
                    Option scam - I foolishly bought into the concept of
                    options. $350,000 was charged to my Visa credit card through
                    my bank - November 2021 to June 2022
                  </p>
                </div>
              </li>

              <li class="media">
                <el-image
                  style="
                    width: 3.25rem;
                    height: 3.25rem;
                    margin-right: 1rem;
                    border-radius: 0.325rem;
                  "
                  :src="require('@/assets/images/25.jpg')"
                ></el-image>
                <div class="media-body">
                  <h5 style="font-size: 1rem; line-height: 1.75rem">
                    Victor Bradford
                  </h5>
                  <el-rate v-model="value6" disabled text-color="#ff9900">
                  </el-rate>
                  <p
                    style="
                      margin-left: -4.25rem;
                      font-size: 11px;
                      line-height: 150%;
                    "
                  >
                    <i class="el-icon-date"></i>
                    &nbsp;18 Mar ,2023
                  </p>
                  <h5
                    style="
                      font-size: 1rem;
                      line-height: 1.75rem;
                      margin-left: -4.25rem;
                    "
                  >
                    Cryptocurrency Scams
                  </h5>
                  <p style="font-size: 0.875rem; margin-left: -4.25rem">
                    After writing off a large sum of money to an online
                    cryptocurrency scam, I was contacted by Robert Tsigler
                    Solicitors in February 2022 and after some per
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!-- <Footer></Footer> -->
  </div>
</template>

<script>
// @ is an alias to /src
// import NavBar from "@/components/nav/NavBar.vue";
// import Footer from "@/components/footer/Footer.vue";

export default {
  name: "HomeView",
  components: {},
  data() {
    return {
      show: true,
      screenWith: null,
      dialogVisible: false,
      form: {
        star: "5",
      },
      value1: 5,
      value2: 4,
      value3: 4,
      value4: 5,
      value5: 4,
      value6: 5,
      dist: true,
    };
  },
  mounted() {
    this.screenWith = document.body.clientWidth;
    // console.log(this.screenWith);
    window.onresize = () => {
      return (() => {
        this.screenWith = document.body.clientWidth;
      })();
    };
  },
  watch: {
    screenWith: {
      handler: function (val) {
        if (val < 1200) {
          // this.show = false;
          this.dist = false;
          // console.log(val);
        } else {
          // this.show = true;
          // console.log(123)
          this.dist = true;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    onDialog() {
      this.dialogVisible = true;
    },
    submit() {
      this.$message({
        duration: 1000,
        message: "successfully",
        type: "success",
      });
      this.form = {
        star: "5",
      };
    },
  },
};
</script>

<style>
@media (max-width: 767px) {
  .StyledHeadlineHero_bg-media__PeO3j {
    /* min-height: calc(16.5625rem + 7.5rem); */
    /* height: 50%; */
    min-height: calc(13rem);
  }
}

@media (min-width: 1200px) {
  .dynamic-style {
    width: 100%;
    height: 100%;
  }
}

.StyledHeadlineHero_main__Xc4mJ {
  --searchbar-bg: transparent;
  background-color: #f8f8f8;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  position: relative;
  overflow: hidden;
  background-color: var(--searchbar-bg);
}

.StyledHeadlineHero_bg-media__PeO3j {
  /* height: 34.375rem; */
  width: 100%;
  position: relative;
}

.StyledHeadlineHero_video-container__AQ8yE {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.StyledHeadlineHero_bg-media__PeO3j.StyledHeadlineHero_has-video__zS5P0
  .StyledHeadlineHero_video-container__AQ8yE {
  display: block;
}

/* .StyledHeadlineHero_video__fvFZC {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
} */
</style>
